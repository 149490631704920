<template>
  <div class="page-header signup-page section-image">
    <div class="page-header-image" v-bind:style="{ backgroundImage: 'url(' + event.cover + ')' }"></div>
    <div class="content">
      <div class="container" v-if="!loading">
        <div class="row">
          <div class="col-md-4 ml-auto mr-auto" v-html="event.register_desc"></div>
          <div class="col-md-5 mr-auto">
            <div class="card card-signup">
              <div class="card-body">
                <h4 class="card-title text-center">Zarejestruj</h4>
                <div class="social text-center">
                  <h5 class="card-description">na {{ event.name }}</h5>
                </div>
                <form @submit.prevent="handleSubmit">
                  <alert type="success" v-show="alert">
                    <div class="alert-icon">
                      <i class="now-ui-icons ui-2_like"></i>
                    </div>
                    <strong>Dobra robota!</strong> Udało Ci się zarejestrować.
                  </alert>
                  <alert type="danger" v-show="error">
                    <div class="alert-icon">
                      <i class="now-ui-icons sport_user-run"></i>
                    </div>
                    <strong>Ajć!</strong> Coś poszło nie tak ({{ this.error }}).
                  </alert>
                  <alert type="danger" v-if="!loading && !event.status === 'closed'">
                    <div class="alert-icon">
                      <i class="now-ui-icons sport_user-run"></i>
                    </div>
                    <strong>No nie!</strong> Jak mogłeś/aś przegapić zapisy!
                  </alert>
                  <alert type="info" v-if="!loading && event.status === 'not_started'">
                    <div class="alert-icon">
                      <i class="now-ui-icons sport_user-run"></i>
                    </div>
                    <strong>Czekaj!</strong> ale zapisy jeszcze nie wystartowały!
                  </alert>

                  <div v-if="is_distances() && $v.form.distance.$error" class="invalid-feedback d-block">
                    <span class="error" v-if="!$v.form.distance.required">Pole jest wymagane</span>
                  </div>
                  <el-select
                      v-if="is_distances()"
                      class="select-primary dropdown-toggle"
                      v-model="form.distance"
                      placeholder="Wybierz zawody..."
                  >
                    <el-option
                        v-for="distance in distances"
                        class="select-primary dropdown-item"
                        :value="distance.id"
                        :label="distance.name"
                        :key="distance.id"
                    >
                    </el-option>
                  </el-select>

                  <div v-if="$v.form.email.$error" class="invalid-feedback d-block">
                    <span class="error" v-if="!$v.form.email.required">Pole jest wymagane</span>
                    <span class="error" v-if="!$v.form.email.email">Podano niepoprawny e-mail</span>
                  </div>
                  <fg-input
                      placeholder="E-mail..."
                      v-model="form.email"
                      addon-left-icon="now-ui-icons ui-1_email-85"
                  ></fg-input>

                  <div v-if="$v.form.first_name.$error || $v.form.last_name.$error" class="invalid-feedback d-block">
                    <div class="error" v-if="!$v.form.first_name.required">Pole imię jest wymagane</div>
                    <div class="error" v-if="!$v.form.first_name.minLength">Pole jest zbyt krótkie</div>
                    <div class="error" v-if="!$v.form.first_name.maxLength">Pole jest zbyt długie</div>
                    <div class="error" v-if="!$v.form.first_name.isUpper">
                      Imię z dużej litery. Pole nie może zawierać spacji.
                    </div>
                    <div class="error" v-if="!$v.form.last_name.required">Pole nazwisko jest wymagane</div>
                    <div class="error" v-if="!$v.form.last_name.minLength">Pole jest zbyt krótkie</div>
                    <div class="error" v-if="!$v.form.last_name.maxLength">Pole jest zbyt długie</div>
                    <div class="error" v-if="!$v.form.last_name.isUpper">
                      Nazwisko z dużej litery. Pole nie może zawierać spacji.
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5 first_name">
                      <fg-input
                          placeholder="Imię..."
                          v-model="form.first_name"
                          v-on:keyup="setSex"
                          addon-left-icon="now-ui-icons users_circle-08"
                      ></fg-input>
                    </div>
                    <div class="col-7 last_name">
                      <fg-input
                          placeholder="Nazwisko..."
                          v-model="form.last_name"
                          addon-left-icon="now-ui-icons text_caps-small"
                      ></fg-input>
                    </div>
                  </div>

                  <div v-if="$v.form.birth_date.$error" class="invalid-feedback d-block">
                    <div class="error" v-if="!$v.form.birth_date.required">Pole jest wymagane</div>
                  </div>
                  <div class="row">
                    <div class="col-9">
                      <fg-input>
                        <el-date-picker
                            v-model="form.birth_date"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="Data urodzenia..."
                            default-value="1985-05-23"
                            popper-class="date-picker date-picker-primary"
                        ></el-date-picker>
                      </fg-input>
                    </div>
                    <div class="col-3 sex">
                      <n-switch v-model="sex" on-text="K" off-text="M"></n-switch>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="$v.form.city.$error" class="invalid-feedback d-block">
                      <div class="error" v-if="!$v.form.city.required">Pole miasto jest wymagane</div>
                      <div class="error" v-if="!$v.form.city.minLength">Pole miasto jest zbyt krótkie</div>
                      <div class="error" v-if="!$v.form.city.maxLength">Pole miasto jest zbyt długie</div>
                      <div class="error" v-if="!$v.form.city.isUpper">
                        Wpisz pole poprawnie z dużej litery. Spacja na końcu jest niedozwolona.
                      </div>
                    </div>
                    <div class="col-7 city">
                      <fg-input
                          placeholder="Miasto..."
                          v-model="form.city"
                          addon-left-icon="now-ui-icons travel_istanbul"
                      ></fg-input>
                    </div>
                    <div class="col-5 country">
                      <el-select
                          class="select dropdown-toggle"
                          v-model="form.country"
                          :filterable="true"
                          placeholder="Kraj"
                      >
                        <el-option
                            v-for="(value, key) in countries"
                            class="select-primary dropdown-item"
                            :value="key"
                            :label="value"
                            :key="key"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="$v.form.club.$error" class="invalid-feedback d-block">
                      <div class="error" v-if="!$v.form.club.minLength">Pole jest zbyt krótkie</div>
                      <div class="error" v-if="!$v.form.club.maxLength">Pole jest zbyt długie</div>
                      <div class="error" v-if="!$v.form.club.isUpper">
                        Wpisz pole poprawnie z dużej litery. Spacja na końcu jest niedozwolona.
                      </div>
                    </div>
                    <div class="col-12">
                      <fg-input
                          placeholder="Klub..."
                          v-model="form.club"
                          addon-left-icon="now-ui-icons sport_user-run"
                      ></fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="$v.form.phone.$error" class="invalid-feedback d-block">
                      <div class="error" v-if="!$v.form.phone.required">Pole jest wymagane</div>
                      <div class="error" v-if="!$v.form.phone.minLength">Pole jest zbyt krótkie</div>
                      <div class="error" v-if="!$v.form.phone.maxLength">Pole jest zbyt długie</div>
                    </div>
                    <div class="col-12">
                      <fg-input
                          placeholder="Numer telefonu..."
                          v-model="form.phone"
                          type="number"
                          addon-left-icon="now-ui-icons tech_mobile"
                      ></fg-input>
                    </div>
                  </div>
                  <div class="row" v-for="details in this.event.details" :key="details.id">
                    <div v-if="$v.form[details.column].$error" class="invalid-feedback d-block">
                      <div class="error" v-if="!$v.form[details.column].required">Pole jest wymagane</div>
                    </div>
                    <div class="col-12 mt-2" v-if="['string', 'integer'].includes(details.type)">
                      <fg-input
                          :placeholder="details.name"
                          v-model="form[details.column]"
                      ></fg-input>
                    </div>
                    <div class="el-col-md-push-2 mb-2" v-if="details.type == 'bool'" >
                      <n-checkbox v-model="form[details.column]" class="ext-fields small">
                        {{ details.name }}
                      </n-checkbox>
                    </div>
                  </div>

                  <div class="form-check confirm-terms-and-conditions">
                    <label for="conditions" class="form-check-label">
                      <input id="conditions" type="checkbox" class="form-check-input" v-model="conditions"/>
                      <span class="form-check-sign"></span>
                      Zgadzam się na <a @click="modals.terms = true">warunki</a>
                      uczestnictwa.
                    </label>
                  </div>
                  <div class="card-footer text-center" v-if="this.event.slug">
                    <router-link
                        :to="{ name: 'event', params: { name: this.event.slug } }"
                        class="btn btn-round btn-default btn-lg"
                    >
                      Powrót
                    </router-link>

                    <button
                        class="btn btn-round btn-primary btn-lg"
                        v-if="event.status === 'open'"
                        :disabled="isDisabled"
                    >
                      Zarejestruj
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal
          id="terms"
          :show.sync="modals.terms"
          headerClasses="justify-content-center"
          class="modal-primary"
          :show-close="false"
      >
        <div slot="header" class="modal-terms">
          <i class="now-ui-icons users_circle-08"></i>
        </div>
        <p>
          Oświadczam, iż jestem świadomy zagrożeń, ryzyka i obciążeń fizycznych jakie wiążą się ze startem w zawodach.
          Oświadczam, że jestem świadomy swojego stanu zdrowia, tym samym że nie istnieją żadne przeciwskazania medyczne
          do wzięcia przeze mnie udziału w zawodach. Oświadczam, że posiadam niezbędne umiejętności do startu w
          zawodach, biorę w nim udział dobrowolnie, ponosząc z tego tytułu pełną i wyłączną odpowiedzialność.
          Oświadczam, że zapoznałem się z regulaminem zawodów i akceptuję jego warunki. Wyrażam zgodę na wykorzystywanie
          danych osobowych zawartych na karcie zgłoszeniowej (zgodnie z ustawa z dnia 10 maja 2018 r. o ochronie danych
          osobowych (Dz. U. 2018 r. poz. 1000 t. j.) oraz RODO – rozporządzenie Parlamentu Europejskiego i Rady (UE)
          2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
          osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE) przez Organizatora
          w zakresie i przez okres niezbędny dla organizacji, przeprowadzenia i promocji zawodów, wyłonienia zwycięzców
          i odbioru nagród (cel przetwarzania). Przetwarzanie danych, obejmuje także publikację wizerunku zawodnika,
          imienia i nazwiska wraz z nazwą miejscowości, w której zamieszkuje, jego kategorię wiekową, rok urodzenia,
          płeć, nazwę teamu/klubu, uzyskany podczas zawodów wynik – w każdy sposób, w jaki publikowana lub
          rozpowszechniana będzie taka informacja dla potrzeb organizacji zawodów.
        </p>
        <template slot="footer">
          <n-button type="neutral" link @click.native="modals.terms = false">Zamknij</n-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import {Alert, FormGroupInput, Modal, Switch, Checkbox} from "@/components";
import {
  email,
  helpers,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";
import moment from "moment";

const isUpper = (value) =>
    !helpers.req(value) || /[AĄBCĆDEĘFGHIJKLŁMNŃOÓPRSŚTUWYZŹŻVXQ][aąbcćdeęfghijklłmnńoóprsśtuwyzźżvxq]+$/.test(value);

export default {
  components: {
    Alert,
    [FormGroupInput.name]: FormGroupInput,
    [Switch.name]: Switch,
    [Checkbox.name]: Checkbox,
    required,
    Modal,
    email,
    filters: {
      capitalize: function (value) {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
    },
  },
  mounted: function () {
    this.getEvent(this.$route.params.name);
  },
  computed: {
    isDisabled: function () {
      return !this.conditions || this.submitted;
    },
    sex: {
      get() {
        return this.form.sex == "f" ? true : false;
      },
      set(newVal) {
        this.form.sex = newVal ? "f" : "m";
      },
    },
  },
  methods: {
    rowCompute: function (rows) {
      return rows / this.event.details.length;
    },
    is_distances: function () {
      return this.distances.length > 0;
    },
    distanceRequired: function (value) {
      return !this.is_distances() || this.distances.filter((distance) => distance.id === value).length > 0;
    },
    getEvent: function (name) {
      this.$http.get(process.env.VUE_APP_BACKEND_URL + "/events/" + name + "/").then((response) => {
        this.event = response.data;
        this.loading = false;
        this.eventEmit(this.event);
        this.distances = this.event.distances;
        let tshirt_choices = this.event.details.filter((detail) => detail.column === "tshirt");
        if (tshirt_choices.length > 0) {
          this.tshirt = tshirt_choices[0].choices;
        }
      });
    },
    eventEmit: function (event) {
      this.$root.$emit("event", event);
    },
    handleSubmit(e) {
      this.submitted = true;
      this.form.event = this.event.id;

      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.submitted = false;
        return;
      }
      this.form.birth_date = this.dateFormat(this.form.birth_date);
      this.$http
          .post(process.env.VUE_APP_BACKEND_URL + "/members/", this.form)
          .then((response) => {
            this.data = response.data;
            this.alert = true;
            setTimeout(() => {
              this.$router.push({name: "event", params: {name: this.event.slug}});
            }, 3000);
          })
          .catch((reason) => {
            this.error = reason.response.data[0] || reason;
            this.submitted = false;
          });
    },
    dateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    setSex: function () {
      if (this.form.first_name.slice(-1) == "a") {
        this.form.sex = "f";
      } else {
        this.form.sex = "m";
      }
    },
    validateFields: function (field, value) {
      if (this.event.details.filter((detail) => detail.column === field && detail.required === true).length > 0) {
        return !!value;
      }
      return true;
    },
    validateField1: function (value) {
      return this.validateFields("field_1", value)
    },
    validateField2: function (value) {
      return this.validateFields("field_2", value)
    },
    validateField3: function (value) {
      return this.validateFields("field_3", value)
    },
    validateField4: function (value) {
      return this.validateFields("field_4", value)
    },
    validateField5: function (value) {
      return this.validateFields("field_5", value)
    },
  },
  validations() {
    return {
      form: {
        email: {required, email},
        first_name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(20),
          isUpper,
        },
        last_name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(30),
          isUpper,
        },
        birth_date: {required},
        city: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(30),
          isUpper,
        },
        club: {
          minLength: minLength(3),
          maxLength: maxLength(30),
          isUpper,
        },
        phone: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(12),
        },
        distance: {
          required: this.distanceRequired,
        },
        tshirt: {},
        field_1: {
          required: this.validateField1,
        },
        field_2: {
          required: this.validateField2,
        },
        field_3: {
          required: this.validateField3,
        },
        field_4: {
          required: this.validateField4,
        },
        field_5: {
          required: this.validateField5,
        },
      },
    };
  },
  data: function () {
    return {
      submitted: false,
      event: {},
      baseUrl: null,
      token: "",
      profile: "",
      alert: false,
      error: false,
      data: "",
      distances: [],
      tshirt: [],
      loading: true,
      form: {
        event: null,
        first_name: "",
        last_name: "",
        email: "",
        birth_date: "",
        sex: "",
        city: "",
        country: "PL",
        club: "",
        phone: "",
        distance: "",
        tshirt: "",
        field_1: null,
        field_2: null,
        field_3: null,
        field_4: null,
        field_5: null,
      },
      pickers: {
        datePicker: "",
      },
      conditions: false,
      modals: {
        terms: false,
      },
      countries: {
        AF: "Afghanistan",
        AX: "Aland Islands",
        AL: "Albania",
        DZ: "Algeria",
        AS: "American Samoa",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarctica",
        AG: "Antigua And Barbuda",
        AR: "Argentina",
        AM: "Armenia",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        BS: "Bahamas",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivia",
        BA: "Bosnia And Herzegovina",
        BW: "Botswana",
        BV: "Bouvet Island",
        BR: "Brazil",
        IO: "British Indian Ocean Territory",
        BN: "Brunei Darussalam",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BI: "Burundi",
        KH: "Cambodia",
        CM: "Cameroon",
        CA: "Canada",
        CV: "Cape Verde",
        KY: "Cayman Islands",
        CF: "Central African Republic",
        TD: "Chad",
        CL: "Chile",
        CN: "China",
        CX: "Christmas Island",
        CC: "Cocos (Keeling) Islands",
        CO: "Colombia",
        KM: "Comoros",
        CG: "Congo",
        CD: "Congo, Democratic Republic",
        CK: "Cook Islands",
        CR: "Costa Rica",
        CI: 'Cote D"Ivoire',
        HR: "Croatia",
        CU: "Cuba",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DK: "Denmark",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EC: "Ecuador",
        EG: "Egypt",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        ET: "Ethiopia",
        FK: "Falkland Islands (Malvinas)",
        FO: "Faroe Islands",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        GF: "French Guiana",
        PF: "French Polynesia",
        TF: "French Southern Territories",
        GA: "Gabon",
        GM: "Gambia",
        GE: "Georgia",
        DE: "Germany",
        GH: "Ghana",
        GI: "Gibraltar",
        GR: "Greece",
        GL: "Greenland",
        GD: "Grenada",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HM: "Heard Island & Mcdonald Islands",
        VA: "Holy See (Vatican City State)",
        HN: "Honduras",
        HK: "Hong Kong",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IR: "Iran, Islamic Republic Of",
        IQ: "Iraq",
        IE: "Ireland",
        IM: "Isle Of Man",
        IL: "Israel",
        IT: "Italy",
        JM: "Jamaica",
        JP: "Japan",
        JE: "Jersey",
        JO: "Jordan",
        KZ: "Kazakhstan",
        KE: "Kenya",
        KI: "Kiribati",
        KR: "Korea",
        KP: "North Korea",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: 'Lao People"s Democratic Republic',
        LV: "Latvia",
        LB: "Lebanon",
        LS: "Lesotho",
        LR: "Liberia",
        LY: "Libyan Arab Jamahiriya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MO: "Macao",
        MK: "Macedonia",
        MG: "Madagascar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MU: "Mauritius",
        YT: "Mayotte",
        MX: "Mexico",
        FM: "Micronesia, Federated States Of",
        MD: "Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MS: "Montserrat",
        MA: "Morocco",
        MZ: "Mozambique",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NL: "Netherlands",
        AN: "Netherlands Antilles",
        NC: "New Caledonia",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolk Island",
        MP: "Northern Mariana Islands",
        NO: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palestinian Territory, Occupied",
        PA: "Panama",
        PG: "Papua New Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippines",
        PN: "Pitcairn",
        PL: "Poland",
        PT: "Portugal",
        PR: "Puerto Rico",
        QA: "Qatar",
        RE: "Reunion",
        RO: "Romania",
        RU: "Russian Federation",
        RW: "Rwanda",
        BL: "Saint Barthelemy",
        SH: "Saint Helena",
        KN: "Saint Kitts And Nevis",
        LC: "Saint Lucia",
        MF: "Saint Martin",
        PM: "Saint Pierre And Miquelon",
        VC: "Saint Vincent And Grenadines",
        WS: "Samoa",
        SM: "San Marino",
        ST: "Sao Tome And Principe",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        ZA: "South Africa",
        GS: "South Georgia And Sandwich Isl.",
        ES: "Spain",
        LK: "Sri Lanka",
        SD: "Sudan",
        SR: "Suriname",
        SJ: "Svalbard And Jan Mayen",
        SZ: "Swaziland",
        SE: "Sweden",
        CH: "Switzerland",
        SY: "Syrian Arab Republic",
        TW: "Taiwan",
        TJ: "Tajikistan",
        TZ: "Tanzania",
        TH: "Thailand",
        TL: "Timor-Leste",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad And Tobago",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TC: "Turks And Caicos Islands",
        TV: "Tuvalu",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UM: "United States Outlying Islands",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VE: "Venezuela",
        VN: "Vietnam",
        VG: "Virgin Islands, British",
        VI: "Virgin Islands, U.S.",
        WF: "Wallis And Futuna",
        EH: "Western Sahara",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe",
      },
    };
  },
};
</script>
<style scoped>
.el-input__prefix,
.el-input__suffix {
  color: #303133;
  left: 15px;
}

#terms .now-ui-icons {
  font: normal normal normal 34px "Nucleo Outline" !important;
}

.page-header {
  min-height: 100vh;
  max-height: 100%;
}

.tshirt {
  margin-top: -11px;
}

.ext-fields {
  padding-top: 0;
  margin-left: -26px;
  margin-top: 0;
}

@media (max-width: 1000px) {
  .col-md-4 {
    display: none;
  }

  .col-md-5 {
    margin-top: 50px;
    bottom: 10px;
    flex: inherit;
    max-width: inherit;
  }

  .signup-page .card-signup {
    margin-bottom: 0;
  }
}

.confirm-terms-and-conditions {
  margin-top: 60px;
}
</style>
